<template>
  <div class="divStyle">
    <!-- 班级选择 -->
    <SingleSelection
      :result="reactiveParams.team.result"
      :columns="reactiveParams.team.columnsTeam"
      :label="reactiveParams.team.label"
      :placeholder="'请选择班组'"
      :customFieldName="reactiveParams.team.customFieldName"
      :required="reactiveParams.team.required"
      @onConfirm="onConfirmbz"
    />
    <!-- 员工选择 -->
    <SingleSelection
      :result="reactiveParams.staff.result"
      :columns="reactiveParams.staff.columnsTeam"
      :label="reactiveParams.staff.label"
      :placeholder="'请选择员工'"
      :customFieldName="reactiveParams.staff.customFieldName"
      :required="reactiveParams.staff.required"
      @onConfirm="onConfirmyg"
    />
    <!-- 岗位选择 -->
    <SingleSelection
      :result="reactiveParams.laStations.result"
      :columns="reactiveParams.laStations.columnsTeam"
      :label="reactiveParams.laStations.label"
      :placeholder="'请选择岗位'"
      :customFieldName="reactiveParams.laStations.customFieldName"
      :required="reactiveParams.laStations.required"
      @onConfirm="onConfirmgw"
    />
    <!-- 考勤 -->
    <SingleSelection
      :result="reactiveParams.check.result"
      :columns="reactiveParams.check.columnsTeam"
      :label="reactiveParams.check.label"
      :placeholder="'请选择考勤'"
      :customFieldName="reactiveParams.check.customFieldName"
      :required="reactiveParams.check.required"
      @onConfirm="onConfirmkq"
    />
    <!-- 方式 -->
    <SingleSelection
      :result="reactiveParams.way.result"
      :columns="reactiveParams.way.columnsTeam"
      :label="reactiveParams.way.label"
      :placeholder="'请选择方式'"
      :customFieldName="reactiveParams.way.customFieldName"
      :required="reactiveParams.way.required"
      @onConfirm="onConfirmfs"
    />
    <!-- 系数 -->
    <van-field name="stepper" label="系数">
      <template #input>
        <van-stepper v-model="minute.stationratio" min="-999999999999" />
      </template>
    </van-field>
    <!-- 基础分 -->
    <van-field name="stepper" label="基础分">
      <template #input>
        <van-stepper v-model="submitform.income" min="-999999999999" />
      </template>
    </van-field>
    <!-- 分值 -->
    <van-field name="stepper" label="分值" required>
      <template #input>
        <van-stepper v-model="submitform.score" min="-999999999999" />
      </template>
    </van-field>
    <!-- 备注 -->
    <van-field
      v-model="submitform.summary"
      rows="2"
      autosize
      label="备注"
      type="textarea"
      maxlength="100"
      placeholder="请输入备注"
      show-word-limit
    />
    <!-- 提交 -->
    <div style="width: 90%; margin: 0 auto; margin-top: 0.5rem">
      <van-button
        type="primary"
        size="normal"
        style="width: 100%; border-radius: 0.2rem"
        @click="submit"
      >
        提交
      </van-button>
    </div>
  </div>
</template>
<script>
  import {
    ref,
    inject,
    reactive,
    toRefs,
    provide,
    getCurrentInstance,
    onMounted,
  } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import SingleSelection from "@/components/AllScreening/SingleSelection";
  import { Toast } from "vant";
  export default {
    components: {
      SingleSelection,
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      const { proxy } = getCurrentInstance();
      // 获取字典信息
      const dictionariesInfo = JSON.parse(localStorage.getItem("dictionaries"));

      const reactiveParams = reactive({
        // 添加班级选择
        team: {
          result: "",
          columnsTeam: [],
          label: "班组",
          customFieldName: { text: "name" },
          required: true,
          id: "",
        },
        // 添加员工选择
        staff: {
          result: "",
          columnsTeam: [],
          label: "员工",
          customFieldName: { text: "name" },
          required: true,
          id: "",
        },
        // 添加岗位选择
        laStations: {
          result: "",
          columnsTeam: [],
          label: "岗位",
          customFieldName: { text: "name" },
          required: true,
          id: "",
        },
        // 考勤选择
        check: {
          result: "",
          columnsTeam: [],
          label: "考勤",
          customFieldName: { text: "name" },
          required: false,
          id: "",
        },
        // 方式选择
        way: {
          result: "",
          columnsTeam: [],
          label: "方式",
          customFieldName: { text: "name" },
          required: false,
          id: "",
        },
        // 备注
        message: "",
        // 接口上传信息
        from: {
          companyId: "",
          groupId: "",
        },
        minute: {
          coefficient: 0,
          basiCost: 0,
          score: 0,
        },

        // 提交form信息
        submitform: {
          companyId: route.query.id,
          condition: "1",
          conditionName: "出勤",
          customerGroupId: "",
          customerId: "",
          customerName: "",
          daysgroupId: "1626021472232525826",
          departmentId: sessionStorage.getItem("organizeId"),
          flight: route.query.flight,
          groupId: "97005121526825484",
          income: 0,
          score: 0,
          station: "采煤机司机",
          stationId: "97005121526832704",
          stationmode: "3",
          stationratio: 0,
          summary: "备注测试",
          workdate: "2023-02-16",
        },
      });
      reactiveParams.way.columnsTeam = dictionariesInfo.postPattern;
      reactiveParams.check.columnsTeam = dictionariesInfo.check;
      const list = async () => {
        // 获取生产班组
        reactiveParams.from.companyId = route.query.id;
        let ProductionTeamList = await proxy.$Api.get(
          proxy.$consultiveManagementApi.getLaGroupslist,
          { companyId: route.query.id }
        );

        reactiveParams.team.columnsTeam = ProductionTeamList.data.data;
        reactiveParams.team.columnsTeam.forEach((item) => {
          item.nametext = `${item.name}|${item.pyjm}`;
        });
      };

      const staffPostList = async () => {
        // 获取员工信息
        let laCustomersList = await proxy.$Api.get(
          proxy.$laDaysGroupsApi.laCustomersList,
          reactiveParams.from
        );
        reactiveParams.staff.columnsTeam = laCustomersList.data.data;
        reactiveParams.staff.columnsTeam.forEach((item) => {
          item.nametext = `${item.name}|${item.pyjm}`;
        });
        // 获取岗位信息
        let laStationsList = await proxy.$Api.get(
          proxy.$laDaysGroupsApi.laStationsList,
          { groupId: reactiveParams.from.groupId }
        );
        reactiveParams.laStations.columnsTeam = laStationsList.data.data;
      };

      onMounted(() => {
        list();
      });

      function onConfirmbz(e) {
        // 赋值回显获取获取下一级数据
        reactiveParams.submitform.customerGroupId = e.id;
        reactiveParams.team.result = e.nametext;
        // 获取员工列表 || 岗位列表
        staffPostList();
      }

      // 员工确认
      function onConfirmyg(e) {
        reactiveParams.staff.result = e.nametext;
        reactiveParams.submitform.customerName = e.name;
        reactiveParams.submitform.customerId = e.id;
      }

      // 岗位确认;
      function onConfirmgw(e) {
        reactiveParams.laStations.result = e.name;
        reactiveParams.laStations.id = e.id;
      }

      // 考勤
      function onConfirmkq(e) {
        reactiveParams.check.result = e.name;
        reactiveParams.check.id = e.id;
      }

      // 方式
      function onConfirmfs(e) {
        reactiveParams.way.result = e.name;
        reactiveParams.way.id = e.id;
      }

      // 提交
      const submit = async () => {
        let laDaysCustomersList = await proxy.$Api.post(
          proxy.$laDaysGroupsApi.laDaysCustomers,
          {}
        );
      };
      return {
        ...toRefs(reactiveParams),
        reactiveParams,
        onConfirmbz,
        onConfirmyg,
        onConfirmgw,
        onConfirmkq,
        onConfirmfs,
        submit,
      };
    },
  };
</script>
<style scoped>
  .divStyle {
    height: 100vh;
    background-color: #fff;
  }
</style>
